<template>

<div class="foot">

	<div class="foot-inner">

		<div class="foot-text">

			&#169; 2023 Premier Board Game Events LLC

		</div>

		<div class="foot-links">

			<router-link :to="{ name: 'Privacy' }" class="foot-links-item">Privacy Policy</router-link>
			<router-link :to="{ name: 'Terms' }" class="foot-links-item">Terms of Use</router-link>
			<div class="foot-links-item"><a href="mailto:support@cardboardevents.com">Contact Support</a></div>

		</div>

	</div>

</div>

</template>

<script>

export default {

}

</script>

<style>

.foot {
	background-color: #1e3054;
	padding: 60px 0px;
	width: 100%;
}

.is-mobile .foot {
	padding: 40px 0px;
}

.foot-inner {
	width: 100%;
	margin: 0px auto;
	max-width: 1000px;
	display: flex;
	justify-content: space-between;
}

.is-mobile .foot-inner {
	flex-direction: column;
	align-items: center;
}

.foot-text {
	font-size: 14px;
	color: #fff;
	font-weight: 300;
}

.is-mobile .foot-text {
	margin-bottom: 20px;
}

.foot-links {
	display: flex;
}

.foot-links-item {
	font-size: 14px;
	font-weight: 300;
	color: #fff;
	margin-left: 20px;
}

.is-mobile .foot-links-item {
	margin: 0px 10px;
}

.foot-links-item:hover {
	text-decoration: underline;
}

</style>
